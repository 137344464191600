$cat1: #f3c145;
$cat2: #7da5a1;
$cat3: #f15455;
$cat4: #936abc;;
$cat5: $seagreen;
$cat6: #73e5ca;

.tribe-mini-calendar{
  // td.tribe-events-has-events{
  //   background-color: $primary;
  //   &:hover a:hover{
  //     background-color: darken($primary, 10%);
  //   }
  // }
}

.tribe-events-adv-list-widget{
  .tribe-events-category-conferences,
  .tribe-events-category-conference{
    .list-date{
      background: $cat1;
    }
  }
  .tribe-events-category-symposium,
  .tribe-events-category-installations{
    .list-date{
      background: $cat2;
    }
  }
  .tribe-events-category-script-to-screen,
  .tribe-events-category-talks{
    .list-date{
      background: $cat3;
    }
  }
  .tribe-events-category-film-series,
  .tribe-events-category-workshops{
    .list-date{
      background: $cat4;
    }
  }
  .tribe-events-category-screening,
  .tribe-events-category-screenings{
    .list-date{
      background: $cat5;
    }
  }
  .tribe-events-category-online{
    .list-date{
      background: $cat6;
    }
  }

}

.menu-item{
  a{
    position: relative;
    &:before{
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      left: -15px;
      top: 6px;
    }
  }
  &.menu-symposium,
  &.menu-installations{
    a:before{
      background-color: $cat2;
    }
    &:hover,
    &.active{
      a{
        background: $cat2;
        color: $white;
      }
    }
  }
  &.menu-script-to-screen,
  &.menu-talks{
    a:before{
      background-color: $cat3;
    }
    &:hover,
    &.active{
      a{
        background: $cat3;
        color: $white;
      }
    }
  }
  &.menu-conference,
  &.menu-conferences{
    a:before{
      background-color: $cat1;
    }
    &:hover,
    &.active{
      a{
        background: $cat1;
        color: $white;
      }
    }
  }
  &.menu-screening,
  &.menu-screenings{
    a:before{
      background-color: $cat5;
    }
    &:hover,
    &.active{
      a{
        background: $cat5;
        color: $white;
      }
    }
  }
  &.menu-film-series,
  &.menu-workshops{
    a:before{
      background-color: $cat4;
    }
    &:hover,
    &.active{
      a{
        background: $cat4;
        color: $white;
      }
    }
  }
  &.menu-online{
    a:before{
      background-color: $cat6;
    }
    &:hover,
    &.active{
      a{
        background: $cat6;
        color: $black;
      }
    }
  }
}


#tribe-events-pg-template,
.tribe-events-pg-template{
  max-width: 100%;
}
#tribe-bar-form{
  background: $white;
  padding-top: 25px;
}
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-]{
  background-color: darken($primary, 10);
}
#tribe-events-content table.tribe-events-calendar .type-tribe_events.tribe-event-featured{
  background-color: $primary;
}
.fms-body--event-single{
  padding-top: 40px;
  .tribe-events-back{
    text-align: right;
  }
}
.datepicker.dropdown-menu{
  z-index: 102!important;
}
.events-list{
  .tribe-events-loop{
    margin: 0;
    .tribe-event-featured{
      background: $lightgray-dark20;
    }
    .tribe-events-list-separator-month{
      text-align: left;
      padding: 0;
      span{
        padding: 0 20px 0 0;
        font-family: $slab;
      }
      &:after{
        border-color: $primary;
        border-width: 2px;
      }
    }
  }
}
.tribe-bar-date-filter{
  padding: 15px 0;
}
.tribe-events-notices{
  background: $lightgray;
  border-color: $lightgray-dark20;
  color: $lightgray-dark45;
  border-radius: 2px;
  text-shadow: none;
}

.tribe-list-widget{
  padding: 0;
  margin: 0;
  .tribe-events-list-widget-events{
    padding: 0;
  }
  .tribe-event-featured{
    background: transparent;
    transition: all 0.25s ease;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    &:after{
      height: 2px;
      content: "";
      position: absolute;
      width: 100%;
      transition: all 0.25s ease;
      background: lighten($primary, 0);
      left: -100%;
      bottom: 0;
    }
    &:hover{
      &:after{
        left: 0;
      }
      .tribe-event-title a{
        color: $primary;
      }
    }
    .tribe-event-image{
      margin: 0;
      padding: 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .tribe-event-title{
      padding: 15px 0 5px;
      margin: 0;
      font-size: 20px;
      a{
        text-decoration: none;
        color: $black;
        font-weight: 400;
      }
    }
    .tribe-event-duration{
      padding: 5px 0 15px;
      font-family: $sans;
      color: $lightgray-dark45;
    }
  }
  .tribe-event-title{
    padding: 15px 0 5px;
    font-size: 20px;
    margin: 0;
    a{
      text-decoration: none;
      color: $black;
      font-weight: 400;
      &:hover{
        color: $primary;
      }
    }
  }
  .tribe-event-duration{
    padding: 5px 0 15px;
    color: $lightgray-dark45;
    font-family: $sans;
  }
}

.tribe-events-category-screening,
.tribe-events-category-screenings{
  h3{
    a{
      color: $cat5;
      font-weight: 400;
    }
  }
}
.tribe-events-category-conference,
.tribe-events-category-conferences{
  h3{
    a{
      color: $cat1;
      font-weight: 400;
    }
  }
}
.tribe-events-category-symposium,
.tribe-events-category-installation{
  h3{
    a{
      color: $cat2;
      font-weight: 400;
    }
  }
}
.tribe-events-category-workshops{
  h3{
    a{
      color: $cat4;
      font-weight: 400;
    }
  }
}
.tribe-events-category-script-to-screen,
.tribe-events-category-talks{
  h3{
    a{
      color: $cat3;
      font-weight: 400;
    }
  }
}
.tribe-events-category-online{
  h3{
    a{
      color: $cat6;
      font-weight: 400;
    }
  }
}
