.fms-journals{
  padding: 15px;
  background: $lightgray-dark20;
  h2{
    color: $white;
    padding: 20px 0;
  }
}
.fms-interests{
  min-height: 500px;
  background: $lightgray-dark45;
}
.fms-publications{
  .item__authors{
    padding-bottom: 5px;
  }
}
.carousel--publications{
  display: none;
  opacity: 0;
  animation: fadeInFromNone 0.25s;
  min-height: 340px;
  &.loaded{
    display: block;
    opacity: 1;
  }
  &.slick-slider{
    .slick-dots{
      li{
        button{
          &:before{
            color: $lightgray-dark45;
          }
        }
      }
    }
  }
}


.carousel--journals{
  max-width: 1600px;
  margin: 0 auto;
  display: none;
  opacity: 0;
  animation: fadeInFromNone 0.25s;
  &.loaded{
    display: block;
    opacity: 1;
  }
  &.slick-slider{
    .slick-slide{
      padding: 15px;
    }
  }
  .journal-item{
    text-align: center;
    background: $white;
    .journal-item__thumb{
      display: block!important;
      width: 100%;
      height: 200px;
      background: $lightgray;
    }
    .journal-item__meta{
      padding: 15px;
      h3{
        margin: 0;
      }
      .item__interests{
        padding: 15px;
        margin: 0;
        li{
          list-style: none;
          display: inline-block;
          &:after{
            content: ", ";
          }
          &:last-child{
            &:after{
              content: "";
            }
          }
        }
      }
    }
  }
}
