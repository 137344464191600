.resource-grid{
  width: 100%;
  opacity: 0;
  transition: all 0.25s ease;
  &.loaded{
    opacity: 1;
  }
  .resource-grid__item{
    width: 50%;
  }
}
