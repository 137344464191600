.fms-event__header{
  background: $lightgray;
  padding: 30px;
  .tribe-events-back{
    text-align: right;
  }


  .header-cat{
    font-size: 1.2em;
  }
  &.fms-event__header--screening,
  &.fms-event__header--screenings{
    background: $cat5;
    h2, .header-cat, a{
      color: $white;
    }
    .header--underline:after{
      background: $white;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }

  }
  &.fms-event__header--script-to-screen,
  &.fms-event__header--talks{
    background: $cat3;
    h2, .header-cat, a{
      color: $white;
    }
    .header--underline:after{
      background: $white;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }
  }
  &.fms-event__header--workshops{
    background: $cat4;
    h2, .header-cat, a{
      color: $white;
    }
    .header--underline:after{
      background: $white;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }
  }
  &.fms-event__header--conference,
  &.fms-event__header--conferences{
    background: $cat1;
    h2, .header-cat, a{
      color: $white;
    }
    .header--underline:after{
      background: $white;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }
  }
  &.fms-event__header--symposium,
  &.fms-event__header--installations{
    background: $cat2;
    h2, .header-cat, a{
      color: $white;
    }
    .header--underline:after{
      background: $white;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }
  }
  &.fms-event__header--online{
    background: $cat6;
    h2, .header-cat, a{
      color: $black;
    }
    .header--underline:after{
      background: $black;
    }
    .tribe-events-notices{
      background: $white;
      color: $black;
    }
  }
}
.tribe-events-single#tribe-events-content{
  margin-bottom: 0;
  padding-bottom: 0;
}
.single-tribe_events{
  padding-bottom: 0;
  margin-bottom: 0;
  .tribe-events-event-meta{
    margin-top: 0;
    background: $lightgray;
    border: 0;
  }
  #tribe-events-footer{
    background: $lightgray;
    padding: 30px 0;
    margin: 0;

  }
  .fms-body--event-single{
    padding-bottom: 0;
  }
}
.tribe-events-notices{
  background: $primary;
  border: 0;
  color: $white;
}
