.events-list{
  #tribe-events-footer{
    padding: 30px 0;
    margin: 0;
    background: $lightgray;
    border: 0;
  }
}
#tribe-events-content.tribe-events-list{
  margin-bottom: 0;
  .tribe-events-loop{
    .tribe-events-event-image{
      width: 100%;
    }
  }
}
