

.tertiary-nav{
  padding: 0;
  margin: 0;

  li{
    list-style: none;
    margin-bottom: 15px;
    a{
      padding: 10px 15px;
      display: block;
      text-decoration: none;
    }
    &.active{
      a{
        background: $gold;
        color: $white;
      }
    }
  }
}

.fms-body{
  padding: 50px 0;
  background: $white;

  &.fms-body--resource{
    h2{
      border-bottom: 3px solid $gold;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
}

.fms-sidebar{
  margin-bottom: 45px;
  h3{
    font-size: 24px;
  }
  ul{
    padding: 0 0 15px;
    li{
      list-style: none;
    }
  }
}

.fms-section{
  padding: 50px 0;
  &.fms-section--publications,
  &.fms-section--creative,
  &.fms-section--courses{
    background: $white;
  }
}

.resource-list{
  padding: 0;
  margin: 0 0 30px;
  li{
    list-style: none;
    padding: 5px 0;
  }
}
