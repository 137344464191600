.fms-journal__hero{
  height: 600px;
  padding: 30px 30px 0;
  background: $lightgray-dark20;
  overflow: hidden;
  .featured-image{
    background: $lightgray-dark45;
    background-position: center;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
  img{
    width: 100%;
    height: auto;
  }
}

.fms-section--journal{
  background: $white;
  position: relative;
  min-height: 400px;
  max-width: 1600px;
  margin: 0 auto;
  .section-journal__content{
    padding-right: calc(50% + 30px);
    .item__interests{
      padding: 0;

      li{
        list-style: none;
        display: inline;
        &:not(:last-child):after{
          content: ", ";
        }
      }
    }
    .journal-ctas{
      padding: 15px 0 0;
      margin-bottom: 0;
      li{
        list-style: none;
        display: inline-block;
        margin-right: 15px;

      }
    }
  }
  .section-journal__image{
    position: absolute;
    top: 0;
    width: 50%;
    min-height: 400px;
    height: 100%;
    &.section-journal__image--right{
      right: 0;
    }
    &.section-journal__image--left{
      left: 0;
    }
  }

}
