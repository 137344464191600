.fms-resources__subnav{
  background: $lightgray-dark20;
  padding: 30px;
  .subnav-wrapper{
    max-width: 1600px;
    margin: 0 auto;
    overflow: auto;
  }
  .quicklink{
    width: 25%;
  }
}
