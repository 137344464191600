.fms-footer{
  background: $white;
  border-top: 2px solid $lightgray-dark20;
  padding: 75px 0 0;
  .widget{
    h3{
      font-size: 24px;
      line-height: 32px;
      @include respond($phone){
        padding: 0 15px;
        font-size: 18px;
      }
    }
    ul{
      padding: 0;
      margin: 0 0 15px;
      .sub-menu{
        display: none;
      }
      @include respond($phone){
        padding: 0 15px;
      }
      li{
        list-style: none;
        font-size: 15px;
        padding: 5px 0;
        a{

        }
      }
    }
  }
  .fms-footer__lower{
    margin-top: 50px;
    padding: 30px 0;
    background: $lightgray-dark60;
    font-size: 14px;
    color: $white;
    position: relative;
    @include respond($phone){
      text-align: center;
    }
    p{
      margin: 0;
    }
  }
}
.fms-footer__social{
  padding: 0;
  text-align: right;
  @include respond($phone){
    text-align: center;
    padding-top: 20px;
  }
  li{
    display: inline-block;
    list-style: none;
    margin-left: 30px;
    &:first-child{
      margin-left: 0;
    }
    a{
      display: block;
      font-size: 18px;
      color: $lightgray-dark20;
      &:hover{
        color: $white;
      }
    }
  }
}
