$phone : '(max-width: 500px)';
$tablet-portrait: '(max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5),
                    (-o-min-device-pixel-ratio: 3/2),
                    (-webkit-min-device-pixel-ratio: 1.5),
                    (min-device-pixel-ratio: 1.5),
                    (min-resolution: 144dpi),
                    (min-resolution: 1.5dppx)';

@mixin respond($media) {
    @media only screen and #{$media} {
        @content;
    }
}
