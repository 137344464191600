@keyframes gradientMove {
    0%{background-size: 100% 4px}
    50%{background-size: 100% 2px}
    100%{background-size: 100% 4px}
}

@keyframes pulse {
    0%{ opacity:.9;transform: skewX(.5deg);}
    25%{ opacity:1;}
    50%{opacity:.9;}
    75%{ opacity:1; transform:skewX(.5deg);}
    100%{opacity:.9;}
}
@keyframes chromaticMove {
    0%{text-shadow: 1px 1px 3px rgba(5, 125, 145,0.5), -1px -1px 3px rgba(12, 79, 251,.5),0px 0px -2px rgba(254, 188, 17, 0.5);}
    50%{text-shadow: 1px 1px 3px rgba(5, 125, 145,0.5), -1px -1px 3px rgba(12, 79, 251,.5),-3px 2px 3px rgba(254, 188, 17, 0.5);}
    100%{text-shadow: 1px 1px 3px rgba(5, 125, 145,0.5), -1px -1px 3px rgba(12, 79, 251,.5),2px -1px 2px rgba(254, 188, 17, 0.5);}
}


@-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
    25% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
    25% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
    25% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }
    25% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
}
