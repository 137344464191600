// Latest Section

.fms-home__latest{
  background: $white;
  position: relative;
  padding: 50px 0;
  h2{
    color: $black;
  }
  .entity{
    .entity__thumb{
      background-repeat: no-repeat;
      background-color: #333;
      &.entity__thumb--portrait{
      }
    }
  }
}



.card--news{
  background: $white;
  border: 1px solid $lightgray-dark20;
  min-height: 220px;

  padding: 15px;
  transition: all 0.15s ease;
  display: block;
  margin-bottom: 30px;
  &:hover{
    text-decoration: none;
    box-shadow: 0 2px 10px 2px rgba(0,0,0,0.15);
  }
  h4{
    text-transform: uppercase;
    color: $lightgray-dark45;
    font-size: 16px;

  }
}

.category-list{
  padding: 0;
  margin: 0;
  li{
    display: inline-block;
    padding: 5px;
    margin-left: 10px;
    a{
      display: block;
      position: relative;
      &:before{
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: 7px;
      }
    }
    &.category-list__item--symposium,
    &.category-list__item--installations{
      a:before{
        background-color: $cat2;
      }
    }
    &.category-list__item--film-series,
    &.category-list__item--workshops{
      a:before{
        background-color: $cat4;
      }
    }
    &.category-list__item--conference,
    &.category-list__item--conferences{
      a:before{
        background-color: $cat1;
      }
    }
    &.category-list__item--screening,
    &.category-list__item--screenings{
      a:before{
        background-color: $cat5;
      }
    }
    &.category-list__item--script-to-screen,
    &.category-list__item--talks{
      a:before{
        background-color: $cat3;
      }
    }
    &.category-list__item--online{
      a:before{
        background-color: $cat6;
      }
    }
  }
}
