.tribe-events-month#tribe-events-content{
  margin-bottom: 0;
  padding-bottom: 0;
  #tribe-events-footer{
    padding: 30px 0;
    margin: 0;
    background: $lightgray;
    border: 0;
  }
  table.tribe-events-calendar{
    margin-bottom: 30px;
  }
}
