// Search

.fms-home__search{
  background: $lightgray-dark20;
  padding: 30px 0 0;
  position: relative;
  @include respond($phone){
    padding: 15px 0;
  }
  p.asp-try{
    padding: 0;
    margin: 0;
    display: none;
  }
  #ajaxsearchprores1_1{
    &.isotopic{
      margin-top: 15px;
    }
  }
  #ajaxsearchpro1_1{
    background: darken($lightgray-dark20, 5%);

    .probox{
      background: darken($lightgray-dark20, 5%);
      position: relative;
      .promagnifier{
        position: absolute;
        background: transparent;
        left: 0;
        float: none;
        width: 50px;
        div.innericon{
          text-align: center;
          width: 50px;
        }
      }
      .proinput{
        padding-left: 50px;
      }
    }
    &.isotopic{
      margin-top: 15px;
      .results{

         .item{
           .asp_content{
             h3{
               a{
                 color: $black;
               }
             }
           }
         }
      }
    }
  }
}
