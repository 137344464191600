.entity.entity--publication{
  .entity__thumb{
    float: left;
    width: 35%;
    height: 300px;
    max-height: 300px;
    background-position: center;
    @include respond($phone){
      width: 100%;
    }
    @include respond($tablet){
      width: 45%;
    }
  }
  .entity__meta{
    float: left;
    width: 65%;
    padding: 15px;
    @include respond($phone){
      width: 100%;
    }
    @include respond($tablet){
      width: 55%;
    }
    h3{
      a{
        padding-top: 5px;
      }
    }
    h5{
      text-transform: uppercase;
      font-size: 14px;
      margin: 0;
      font-family: $sans;
    }
  }
}
.ajax-publications{
  overflow: auto;
  .publication-item{
    width: 50%;
    padding-right: 50px;
    float: left;
  }
}
