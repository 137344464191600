
.entity{
  margin-bottom: 20px;
  background: $white;
  position: relative;
  padding-bottom: 15px;
  overflow: hidden;
  &:after{
    height: 2px;
    content: "";
    position: absolute;
    width: 100%;
    transition: all 0.25s ease;
    background: lighten($primary, 0);
    left: -100%;
    bottom: 0;
  }
  &:hover:not(.entity--disabled){
    &:after{
      left: 0;
    }
    .entity__thumb{
      img{
        @include grayscale(25%);
        // transform: scale(1.1);
      }
    }
    .entity__type{
      background: $primary;
    }
    .entity__meta{
      h3 a{
        color: $primary;
      }
    }
  }
  .entity__type{
    position: absolute;
    background: $black;
    color: $white;
    top: 0;
    right: 0;
    padding: 5px;
    z-index: 10;
    text-transform: uppercase;
    font-size: 13px;
    font-family: $sans;
    transition: all 0.25s ease;
  }
  .entity__thumb{
    width: 100%;
    height: 245px;
    text-align: center;
    position: relative;
    transition: all 0.25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: $lightgray;

    img{
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
      @include grayscale(75%);
      // transform: scale(1);
      transition: all 0.35s ease;
    }
  }
  .entity__meta{
    width: 100%;

    transition: all 0.25s ease;
    h3{
      margin-bottom: 0;
      a{
        color: $black;
        font-size: 20px;
        padding: 15px 0 5px;
        font-family: $slab;
        font-weight: 400;
        display: block;
        text-decoration: none;
      }
    }
    p{
      margin: 0;
      padding-bottom: 10px;
      color: $lightgray-dark45;
      font-size: 14px;
      &.date{
        padding: 5px 0 10px;
      }
      &.label{
        padding-bottom: 0;
        margin-top: 5px;
        small{
          margin-bottom: 0;
        }
        &.label--interests{
          padding-top: 10px;
          border-top: 1px solid $lightgray-dark20;
        }
      }
      font-size: 16px;
    }
    small{
      // margin-top: 8px;
      display: block;
      color: $lightgray-dark20;
      font-weight: 500;
      font-size: 13px;
      margin-bottom: 2px;
      text-transform: uppercase;
    }
    ul{
      padding: 0;
      margin: 0;
      li{
        list-style: none;
        display: inline;
        &:not(:last-child):after{
          content: ", ";
        }
      }
    }
  }
  &.entity--disabled{
    a{
      pointer-events: none;
    }
  }
  &.entity--course{

  }
  &.entity--person{

  }
}
