.fms-creative__hero{
  height: 600px;
  padding: 30px 30px 0;
  background: $lightgray-dark20;
  overflow: hidden;
  .featured-image{
    background: $lightgray-dark45;
    background-position: center;
    width: 100%;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
  img{
    width: 100%;
    height: auto;
  }
}
