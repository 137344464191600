.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  .slick-dots{
    li{
      button{
        &:before{
          font-size: 10px;
          color: $white;
        }
      }
    }
  }
  .slick-arrow:before{
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: $lightgray-dark20;
    opacity: 1;
    content: '';
    display: inline-block;
    height: 32px;
    width: 32px;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top;
  }
  .slick-prev{
    z-index: 100;
    height: 40px;
    width: 40px;
    @include respond($phone){
      display: none!important;
    }
    @include respond($tablet){
      display: none!important;
    }
    &:before {
      left: 2px;
      transform: rotate(-135deg);
    }
  }
  .slick-next{
    z-index: 100;
    height: 40px;
    width: 40px;
    @include respond($phone){
      display: none!important;
    }
    @include respond($tablet){
      display: none!important;
    }

    &:before {
      left: 0;
      transform: rotate(45deg);
    }
  }
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
