.fms-gallery__upper{
  padding: 30px 0;
  .gallery-description{

  }
}
.fms-gallery__hero{
  padding: 0 0 30px;
  background: $lightgray-dark20;
  overflow: hidden;
  .gallery-wrapper{
    width: 100%;
    height: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
  .fms-tabs__nav{
    background: $white;
    li.active{
      a{
        background: $lightgray-dark20;
        color: $white;
      }
    }
  }
}


.carousel--creative{
  display: none;
  opacity: 0;
  animation: fadeInFromNone 0.25s;
  &.loaded{
    display: block;
    opacity: 1;
  }
  &.slick-slider{
    .entity--creative{
      padding: 0 15px 15px;
      padding-left: 0;
    }
    .slick-dots{
      li{
        button{
          &:before{
            color: $lightgray-dark45;
          }
        }
      }
    }
  }
}

.fms-tabs.fms-tabs--gallery{
  background: transparent;
  .fms-tabs__nav{

  }
  .fms-tabs__content{
    padding: 30px 0;
    .fms-tab{
      background: transparent;
    }
  }
}
