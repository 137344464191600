.elementor-widget-heading{
  .elementor-heading-title{
    font-family: $slab;
    font-weight: 300;
  }
}
.elementor-container{
  p.p1{
    font-family: $sans;
    font-size: 16px;
    line-height: 24px;
  }
}
.elementor-widget-tabs{
  .elementor-tabs-wrapper{
    border-bottom: 1px solid $primary;
  }
  .elementor-tab-content{
    padding: 30px 0;
  }
  .elementor-tab-title{
    font-weight: normal;
    color: $lightgray-dark20;
    font-family: $sans;
    &:hover{
      color: $primary;
    }
    &.elementor-active{
      color: $white;
      background: $primary;
    }
  }
}
.elementor-section-wrap{
  background: $lightgray-dark20;

  > .elementor-element{
    background: $white;

    .elementor-tabs-content-wrapper{

    }
    @include respond($phone){
      padding: 15px;
    }
    @include respond($tablet){
      padding: 30px;
    }
    .elementor-heading-title{
      font-family: $slab;
      font-weight: 300;
    }
    &.elementor-inset{
      margin: 30px 30px 0;
      width: calc(100% - 60px);
      border-bottom: 30px solid $lightgray-dark20;
      @include respond($phone){
        padding: 15px;
        border-bottom: 15px solid $lightgray-dark20;
      }
      &.adjacent-top{
        margin-top: 15px;
      }
      &.adjacent-bottom{
        border-bottom: 15px solid $lightgray-dark20;
      }
    }
  }
  .elementor-button{
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 2px;
    font-family: $sans;
  }
}
a.elementor-button{
  text-decoration: none;
}
