.fms-header--course{
  height: auto;
  padding: 30px 0;

  h1{
    color: $white;
    font-weight: 300;
    line-height: 42px;
  }
  h4{
    text-transform: uppercase;
    color: $white;
    font-size: 18px;
    line-height: 24px;
  }
}
.fms-body--course-details{
  background: $lightgray-dark20;
  position: relative;
  color: $white;
  &:before{
    background: $white;
    height: 100%;
    width: calc((100vw - 1200px) / 2);
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
}
.fms-body--course-faculty{

}
