.journal-list--main{
  .fms-inset{
    &:nth-of-type(even){
      .section-journal__content{
        padding-left: calc(50% + 30px);
        padding-right: 30px;
      }
    }
    &:first-of-type{
      padding-top: 0px;
    }
    &:last-of-type{
      padding-bottom: 30px;
    }
  }
}
