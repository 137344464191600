.fms-home__hero{
  position: relative;
  padding: 30px;
  background: $lightgray-dark20;
  @include respond($phone){
    padding: 0;
  }
  .fms-slider-wrapper{
    width: 100%;
    height: 700px;
    background: $lightgray-dark45;
    background-position: center;
    max-width: 1600px;
    margin: 0 auto;
    @include respond($phone){
      height: 500px;
    }
  }

  .fms-slider{
    width: 100%;
    height: 700px;
    // box-shadow: 0px 2px 5px 5px rgba(0,0,0,0.25);
    display: none;
    opacity: 0;
    animation: fadeInFromNone 0.25s;
    @include respond($phone){
      height: 500px;
    }
    &.loaded{
      display: block;
      opacity: 1;
    }
  }
  .fms-slider__item{
    width: 100%;
    height: 700px;
    @include respond($phone){
      height: 500px;
    }
  }
  .fms-overlay-gradient{
    opacity: 0.5;
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 5px;
    width: 100%;
    background: linear-gradient(
    to bottom,
      #000,
      #000 50%,
      #222 50%,
      #222
    );
    background-size: 100% 4px;
    animation: gradientMove 200ms ease infinite;
  }
  .slick-prev,
  .slick-next{
    &:hover{
      &:before{
        border-color: $white;
      }
    }
  }
  .slick-prev{
    left: 50px;
  }
  .slick-next{
    right: 50px;
  }





  .fms-hero__content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-transform: uppercase;
    color: $white;
    z-index: 100;
    //background: $lightgray-dark20;
    width: 50%;
    pointer-events: all;
    @include respond($phone){
      width: 100%;
      left: 0;
      padding: 30px;
    }
    @include respond($tablet){
      width: 100%;
      left: 0;
      padding: 60px;
    }
    @include respond($tablet-portrait){
      width: 100%;
      left: 0;
      padding: 60px;
    }

    h1{
      font-weight: 300;
      margin: 15px 0;
      // text-shadow: 0 2px 15px rgba(0,0,0,0.5);
      text-transform: uppercase;
      // animation: chromaticMove 4500ms ease infinite,pulse ease 200ms  infinite;
      // text-shadow: 3px 2px 2px rgba(251, 12, 12,1), 0px -1px 3px rgba(12, 79, 251,.5),-3px 0px 2px rgba(52, 251, 12, 1);
      @include respond($phone){
        font-size: 24px;
      }
      p{
        font-size: inherit;
        font-family: inherit;
        text-transform: inherit;
      }

    }
    h4{
      font-size: 18px;
      text-transform: uppercase;
      opacity: 0.75;
      @include respond($phone){
        font-size: 14px;
      }
    }
    p{
      font-size: 22px;
      text-shadow: 0 2px 15px rgba(0,0,0,0.5);
      text-transform: none;
      font-family: $slab;
      font-weight: 300;
      line-height: 36px;
      @include respond($phone){
        font-size: 18px;
        line-height: 24px;
      }
    }
    .subheader{
      padding-bottom: 30px;
    }

  }
}
.fms-outline{
  position: absolute;
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  left: 30px;
  top: 30px;
  border: 2px solid $primary;
  z-index: 99;
  @include respond($phone){
    width: calc(100% - 30px);
    height: calc(100% - 34px);
    left: 15px;
    top: 15px;
    border-width: 1px;
  }
}
