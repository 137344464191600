.btn{
  border-radius: 2px;
  text-align: center;
  &.alm-load-more-btn{
    width: 100%;
    cursor: pointer;
    &.done{
      opacity: 0.25;
      cursor: default;
    }
  }
  &.btn--outline{
    border: 1px solid $primary;
    background: 'transparent';
    color: $primary;
  }
  &.btn--block{
    display: block;
    text-align: center;
  }
  &.btn--slide{
    position: relative;
    transition: all 0.2s ease;
    overflow: hidden;
    line-height: 45px;
    height: 45px;
    padding: 0 25px;
    border: 0;
    span{
      position: relative;
      z-index: 1;
      text-transform: uppercase;
      font-family: $sans;
      font-size: 14px;
    }
    &.btn--primary{
      background: $primary;
      color: $white;
    }
    &:before{
      content: "";
      width: 100%;
      height: 100%;
      background-color: lighten($primary, 5);
      position: absolute;
      transition: all 0.2s ease;
      top: 0;
      left: -210px;
      z-index: 0;
    }
    &:hover{
      &:before{
        left: 0;
      }
    }
  }
}
