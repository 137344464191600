// Grid on People Homepage
.person-grid{
  overflow: auto;
  max-width: 1600px;
  margin: 0 auto;
  @include respond($phone){
    max-width: 100%;
  }
  .grid-item{
    width: calc(12.5% - 1px);
    float: left;
    position: relative;
    overflow: hidden;
    margin-right: 1px;

    @include respond($phone){
      width: 50%;
      margin-right: 0;
    }
    @include respond($tablet){
      width: calc(100%/4);
      margin-right: 0;
    }

    &:nth-child(3n){
      .grid-item__thumb{
        background: $white;
      }
    }
    &:hover{
      .grid-item__overlay{
        // transform: scale(1);
      }
      .grid-item__thumb img{
        @include grayscale(15%);
        // transform: scale(1.1);
      }
    }
    .grid-item__thumb{
      height: 160px;
      width: 100%;
      display: block;
      background: $lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      img{
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        @include grayscale(75%);
        // transform: scale(1);
        transition: all 0.35s ease;
      }
    }
    .grid-item__meta{
      padding: 15px;
      height: 100px;
      background: $white;

      a{
        text-decoration: none;
        h4{
          font-size: 18px;
        }
      }
    }
    // .grid-item__overlay{
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   transform: scale(0);
    //   height: 100%;
    //   width: 100%;
    //   background: rgba(0,0,0,0.4);
    //   transition: all 0.35s ease;
    //
    // }
  }

}
