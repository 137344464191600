body,
html{
  margin: 0;
  // background: $lightgray;
}

body > .main{
  padding: 0;
}

body.nav--open,
body.viz--open{
  overflow: hidden;
}


h1,
h2,
h3,
h4,
h5,
h6{
  font-family: $header-font;
  font-weight: 300;
  > a{
    font-family: $header-font;
    font-weight: 300;
  }
}
.header--underline{
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  &.header--center{
    &:after{
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &:after{
    height: 2px;
    content: "";
    width: 75px;
    background: $primary;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
p,
a,
li,
span,
input{
  font-family: $sans;
  font-weight: 200;
  @include respond($phone){
    font-weight: 400;
  }
}
a{
  color: $primary;
  &:hover{
    color: darken($primary, 10);
  }
}
::selection {
  background:$primary;
  color:$white;
}

::-moz-selection {
  background:$primary;
  color:$white;
}

::-webkit-selection {
  background:$primary;
  color:$white;
}

.addthis_inline_share_toolbox{
  margin-bottom: 15px;
}

.fms-inset{
  padding: 30px;
  background: $lightgray-dark20;
  @include respond($phone){
    padding: 15px;
  }
  &.adjacent-top{
    padding-top: 15px;
  }
  &.adjacent-bottom{
    padding-bottom: 15px;
  }
}

.cf::after {
  content: "";
  clear: both;
  display: table;
}





.fms-tabs{
  background: $white;
  .fms-tabs__nav{
    background: $lightgray;
    ul{
      padding: 0;
      margin: 0;
    }
    li{
      display: inline-block;
      a{
        padding: 20px;
        display: block;
        text-decoration: none;
        color: $lightgray-dark60;
      }
      &.active{
        a{
          background: $white;
          color: $primary;
        }
      }
    }
  }
  .fms-tabs__content{
    .fms-tab{
      display: none;
      opacity: 0;
      animation: fadeInFromNone 0.25s;
      // padding: 50px 0;
      background: $white;
      &.active{
        display: block;
        opacity: 1;
      }
    }
  }
}






.detail-block{
  font-family: $sans;
  label{
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: $lightgray-dark45;
    margin-bottom: 0;
  }
  padding: 10px 0;
  ul{
    list-style: none;
    padding: 0;
  }
}

.list-search{
  padding: 0 15px 30px;
  input{
    padding: 10px 0;
    width: 100%;
    border: 0;
    border-bottom: 1px solid $lightgray-dark20;
    font-size: 18px;
    &:focus{
      border-color: $primary;
      outline: 0;
    }
  }
}
.filters-bar{
  background: $lightgray;
  padding: 30px 0;
  .alm-filters{
    .alm-filter{
      margin: 0;
      padding: 0;
      .alm-filter--title{
        h3{
          font-weight: normal;
          padding-top: 0;
        }
      }
      .alm-filter--checkbox{
        display: inline-block;
        padding: 0;
      }
    }
  }
}

.last-external{
  .elementor-tabs-wrapper{
    div:last-of-type{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.section-spotlight--people,
.section-spotlight--academic{
  background: $lightgray-dark20;
  padding: 0 30px;
  @include respond($phone){
    padding: 0;
  }
}
.section-spotlight--academic{
  padding-bottom: 30px;
}
.spotlight-wrapper{
  max-width: 1600px;
  background: $white;
  margin: 0 auto;
  display: none;
  opacity: 0;
  animation: fadeInFromNone 0.25s;
  &.loaded{
    display: block;
    opacity: 1;
  }
  &.slick-slider.slick-dotted{
    margin-bottom: 0;
  }
  .slick-slide{
    height: 400px;
  }
  .spotlight-wrapper__item{
    position: relative;
    min-height: 400px;
    padding: 30px 0;
    .container{
      min-height: 340px;
      position: relative;
    }


    .item-content{
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 10;
      @include respond($phone){
        padding: 20px;
      }
      h4{
        text-transform: uppercase;
        font-size: 18px;
      }
      h2{
        line-height: 48px;
        font-family: $slab;
        background: $white;
        display: inline-block;
        margin: 0;
        p{
          font-size: inherit;
          width: inherit;
          margin: inherit;
        }
      }
      p{
        width: calc(50% - 30px);
        font-size: 18px;
      }
    }
    .item-image{
      width: 50%;
      position: absolute;
      background: $lightgray;
      right: 0;
      top: 0;
      z-index: 9;
      min-height: 400px;
    }
  }

}

@mixin grayscale($value) {
    filter: #{ "grayscale(" + $value + ")" };
}
