// QUICKLINKS SECTION

.fms-home__quicklinks{
  padding: 30px;
  background: $lightgray-dark20;
  overflow: auto;
  @include respond($phone){
    padding: 15px;
  }

  .quicklink-wrapper{
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
  }
  .quicklink{
    width: calc(100%/4);
    @include respond($phone){
      width: 100%;
    }
  }
}

.quicklink{
  float: left;
  font-size: 0;
  height: 225px;
  background: $lightgray-dark45;
  text-align: center;
  position: relative;
  @include respond($phone){
    width: 100%;
    float: none;
    height: 125px;
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.25s ease;
    color: $white;
    position: relative;
    .quicklink-outline{
      position: absolute;
      top: 15px;
      left: 15px;
      border: 1px solid $primary;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      transition: all 0.25s ease;
    }
    h4{
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
      padding: 5px 0;
      font-weight: 300;
    }
    &:hover{
      background: rgba(0, 0, 0, 0.15);
      .quicklink-outline{
        border-color: $white;
      }
    }
  }
}
