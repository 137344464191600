.fms-body--publication-authors{
  .entity{
    .entity__thumb{
      height: 215px;
    }
  }
}
.fms-header--publication{
  height: auto;
  padding: 30px 0;
  h3{
    color: $white;
    font-weight: 300;
    padding: 0;
    margin: 0;
    font-size: 32px;
  }
}
.pub-breadcrumbs{
  text-align: right;
  a{
    color: $white;
    line-height: 32px;
  }
}

.publication-image{
  min-height: 180px;
  background: $lightgray;
  img{
    width: 100%;
  }
}



.fms-section--publication{
  h2 a{
    float: right;
    font-size: 18px;
    line-height: 32px;
  }
}
