.fms-events__upcoming{
  background: $lightgray-dark20;
  padding: 30px;
  .upcoming-wrapper{
    max-width: 1600px;
    margin: 0 auto;
    overflow: auto;

  }
  .upcoming-item{
    position: relative;
    margin-bottom: 30px;
    a{
      display: block;
      background: $lightgray-dark45;
      height: 140px;
      position: relative;
    }
    .upcoming-item__tag{
      background: $primary;
      color: $white;
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      padding: 5px;
      font-size: 13px;
      text-transform: uppercase;
    }
    &.upcoming-item-screening,
    &.upcoming-item-screenings{
      .header--underline:after{
        background: $cat5;
      }
      .upcoming-item__tag{
        background: $cat5;
      }
    }
    &.upcoming-item-script-to-screen,
    &.upcoming-item-talks{
      .header--underline:after{
        background: $cat3;
      }
      .upcoming-item__tag{
        background: $cat3;
      }
    }
    &.upcoming-item-symposium,
    &.upcoming-item-installations{
      .header--underline:after{
        background: $cat2;
      }
      .upcoming-item__tag{
        background: $cat2;
      }
    }
    &.upcoming-item-conference,
    &.upcoming-item-conferences{
      .header--underline:after{
        background: $cat1;
      }
      .upcoming-item__tag{
        background: $cat1;
      }
    }
    &.upcoming-item-online{
      .header--underline:after{
        background: $cat6;
      }
      .upcoming-item__tag{
        background: $cat6;
      }
    }

    .upcoming-item__meta{
      padding: 15px;
      text-align: center;
      background: $white;
      h3{
        margin: 0;
        font-size: 18px;
      }
      .upcoming-item__date{
        padding-top: 15px;
        margin: 0;
      }
    }
  }
}
