// Research Block

.fms-home__research{
  padding: 15px 0;
  background: $lightgray-dark20;
  h3{
    font-size: 22px;
    margin: 0;
    font-family: $slab;
    font-weight: 400;
    color: $black;
    span{
      color: $white;
    }
  }
}
