#ajaxsearchpro2_1{
  background: darken($topbarbg, 5%);
  background-image: none;
  .probox{
    background: darken($topbarbg, 5%);
    background-image: none;
    position: relative;
    .promagnifier{
      position: absolute;
      background: $topbarbg;
      background-image: none;
      left: 0;
      float: none;
      width: 40px;
      div.innericon{
        text-align: center;
        width: 40px;
      }
    }
  }
  .proinput{
    padding-left: 40px;
  }
}
#ajaxsearchprores2_1{
  top: 40px!important;


  &.asp_w.asp_r{
    padding: 0;
    .results{
       .asp_spacer{
         background: transparent;
       }
      .item{
        border: 0;
        padding: 5px;
      }
    }
  }
}
