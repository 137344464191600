$topbarbg: $lightgray-dark60;
.fms-topbar{
  height: 40px;
  background: $topbarbg;
  position: relative;
  margin-bottom: 0;
  .container{
    @include respond($phone){
      padding: 0;
    }
  }
  .fix-nav &{
    margin-bottom: 100px;
    .fms-topbar__search,
    ul,
    .fms-topbar__ucsb{
      margin-top: -50px;
    }
  }
  .fms-topbar__wrapper{
    overflow: auto;
  }
  .fms-topbar__search{
    float: left;
    width: 50%;
    margin-top: 0;
    transition: all 0.15s 0.15s ease;
    @include respond($phone){
      display: none;
    }
  }
  ul{
    margin: 0;
    padding: 0;
    transition: all 0.15s 0.15s ease;
    li{
      display: inline-block;
      a{
        line-height: 40px;
        color: $lightgray-dark20;
        font-size: 14px;
        text-decoration: none;

      }
      &.active,
      &:hover{
        a{
          color: $white;
        }
      }
    }
    &.fms-topbar__nav-links{
      float: right;
      width: 25%;
      position: relative;
      text-align: right;
      @include respond($phone){
        width: 50%;
        padding-right: 20px;
      }
      li{
        margin-left: 20px;
      }
    }
  }
  .fms-topbar__ucsb{
    display: block;
    float: left;
    border-right: 1px solid darken($topbarbg, 5%);
    margin:0;
    padding-right: 20px;
    line-height: 42px;
    color: $lightgray-dark20;
    font-size: 14px;
    text-decoration: none;
    transition: all 0.15s 0.15s ease;
    @include respond($phone){
      width: 50%;
      padding-right: 0;
      padding-left: 15px;
    }
    &:hover{
      color: $white;
    }
    .uc{
      font-family: $slab;
    }
    strong{
      font-weight: 600;
      font-family: $sans;
    }
  }
}


.fms-banner{
  font-size: 0;
  height: 102px;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  background: $white;
  transition: all 0.25s ease;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
  border-top: 2px solid $lightgray-dark60;
  z-index: 101;
  @include respond($phone){

  }
  .fms-banner__wrapper{
    overflow: hidden;
  }
  .fix-nav &{
    height: 102px;
    position: fixed;
    top: 0;
    border-color: $primary;
    &.small{
      height: 77px;
      .brand{
        transform: scale(0.85);
        padding-top: 14px;
        svg{
          top: 0;
        }
      }
      .mobile-nav-toggle{
        line-height: 75px;
      }
      .nav-primary{
        > ul{
          > li{
            > a{
              line-height: 75px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .brand{
    float: left;
    display: block;
    font-weight: 400;
    text-decoration: none;
    padding: 28px 15px;
    transform: scale(1);
    transform-origin: left 50%;
    transition: all 0.25s ease;
    @include respond($phone){
      margin-top: 10px;
    }
    // text-transform: uppercase;
    span{
      transition: all 0.6s ease;
      font-family: $slab;
      font-weight: 400;
      color: $black;
      font-size: 24px;
      @include respond($phone){
        font-size: 18px;
      }

    }
    small{
      display: block;
      font-size: 14px;
      font-family: $slab;
      color: $black;
      line-height: 15px;
      @include respond($phone){
        display: none;
      }
    }
    svg{
      position: absolute;
      top: 15px;
      width: 100%;
      left: 0;
      transition: all 0.25s ease;
    }
    svg line {
      stroke-width: 2;
      stroke: $lightgray;
      fill: none;
      transition: all 0.6s ease-in-out;
    }
    &:hover{
      span{
        // text-shadow: 3px 0 rgba(cyan, .4) , -3px 0 rgba(magenta, .4);
        // text-shadow:
        // 4px 4px 0px #d5d5d5,
        // 7px 7px 0px rgba(0, 0, 0, 0.2);
      }
       svg line{
         stroke: $primary;
         &.L-T {
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
          }
          &.L-B {
            -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
          }
          &.R-T {
            -webkit-transform: translateY(50px);
            transform: translateY(50px);
          }
          &.R-B {
            -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
          }
          &.T-L {
            -webkit-transform: translateX(270px);
            transform: translateX(270px);
          }
          &.T-R {
            -webkit-transform: translateX(-270px);
            transform: translateX(-270px);
          }
          &.B-L {
            -webkit-transform: translateX(270px);
            transform: translateX(270px);
          }
          &.B-R {
            -webkit-transform: translateX(-270px);
            transform: translateX(-270px);
          }
       }
    }

  }
  .mobile-nav-toggle{
    float: right;
    line-height: 100px;
    font-size: 18px;
    padding: 0 15px;
    transition: all 0.25s ease;
    @include respond($large-desktop){
      display: none;
    }
  }
  .nav-primary{
    float: right;

    @include respond($phone){
      display: none;
    }
    @include respond($tablet){
      display: none;
    }

    > ul{
      float: right;
      > li{
        display: inline-block;
        &.menu-item-has-children{
          &:hover{
            ul.sub-menu{
              display: block;
            }
          }
        }
        > a{
          transition: all 0.25s ease;
          line-height: 100px;
          padding: 0 20px;
          color: $black;
          text-decoration: none;
          position: relative;
          display: block;
          font-size: 15px;
          &:hover{
            color: $primary;
          }
        }
        &.active{
          > a{
            color: $primary;
          }
        }
        ul.sub-menu{
          display: none;
          position: absolute;
          background: $lightgray;
          // text-align: right;
          padding: 0;
          // padding-right: calc((100vw - 1120px) / 2);
          // left: 0;
          // width: 100%;
          transition: top 0.25s ease;
          font-size: 14px;
          // top: 100px;
          box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
          li{
            display: block;
            > a{
              transition: all 0.25s ease;
              line-height: 50px;
              padding: 0 20px;
              color: $black;
              text-decoration: none;
              position: relative;
              display: block;
              font-size: 15px;
              // border-bottom: 1px solid $lightgray-dark20;
              &:hover{
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}





.fms-secondary-nav{
  position: relative;
  background: $lightgray;


  ul{
    padding: 0;
    margin: 0;
    li{
      list-style: none;
      display: inline-block;
      @include respond($phone){
        display: block;
        text-align: center;
        &:first-child{
          display: none;
        }
      }
      a{
        line-height: 75px;
        margin-right: 30px;
        color: $black;
        text-decoration: none;
        font-size: 15px;
        @include respond($phone){
          line-height: 50px;
          margin: 0 auto;
        }
      }
      &.active{
        a{
          color: $primary;
        }
      }
      &:hover{
        a{
          color: $primary;
        }
      }
      &:first-child{
        &:after{
          content: "|";
          margin-right: 30px;
        }
      }
    }
  }
}


.fms-header{
  //height: 125px;
  background: $lightgray-dark20 url("../../assets/images/header-bg.jpg") no-repeat center;
  background-size: cover;
  position: relative;
  h1{
    line-height: 100px;
    color: $white;
    margin: 0;
    text-shadow: 0 1px 15px rgba(0,0,0,0.35);
  }
}





.mobile-nav-close{
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
}

.mobile-nav-menu{
  position: fixed;
  top: 0;
  left: 0;
  background: $lightgray url('../images/seal-bg.png') center no-repeat;
  background-size: 85%;
  width: 100%;
  height: 100%;
  z-index: 103;
  display: none;
  opacity: 0;
  animation: fadeInFromNone 0.25s;
  &.active{
    display: block;
    opacity: 1;
  }
  .mobile-nav-wrapper{
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    ul{
      text-align: center;
      li{
        display: block;
        width: 100%;
        a{
          display: block;
          padding: 20px;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
}
