.fms-header--person{
  height: auto;
  padding: 30px 0;
  position: relative;


  h1{
    color: $white;
    line-height: 30px;
    margin-bottom: 10px;
  }
  h4{
    color: $white;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 300;
  }
  .person-meta{
    overflow: auto;
  }
  .person-meta__title{
    padding: 30px 0 0;
    float: left;
    width: 75%;
    ul{
      border-top: 1px solid rgba(255,255,255, 0.25);
      padding: 10px 0;
      margin: 0;
      li{
        display: inline-block;
        color: $white;
        margin-right: 25px;
        a{
          background: $primary;
          color: $white;
          padding: 2px 3px;
        }
      }
    }
  }
  .person-meta__thumb{
    float: left;
    width: 150px;
    height: 150px;
    // border-radius: 150px;
    margin-right: 30px;
    overflow: hidden;
    position: relative;
    img{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      min-width: 100%;
      height: auto;
    }
  }
}
.fms-body--person{

}
.people__bio{
  .btn{
    margin-top: 30px;
  }
}
.people__education{
  .education-list{
    list-style: none;
    padding: 0;
    margin: 0;
    li{
      margin-bottom: 10px;
    }
    small{
      display: block;
    }
    strong{
      font-weight: bold;
    }
  }

}
