.people-list{
  padding: 0;
  margin: 0;
  .people-list__item{
    overflow: auto;
    margin-bottom: 30px;
    .item__thumb{
      float: left;
      width: 100px;
      height: 100px;
      background: $lightgray;
      border-radius: 100px;
      overflow: hidden;
      text-align: center;
      position: relative;
      display: block;
      img{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);
      }
    }
    .item__meta{
      float: left;
      width: calc(100% - 100px);
      padding: 15px;
      h3{
        font-size: 22px;
        a{
          color: $black;
          &:hover{
            color: $primary;
          }
        }
      }
      p{
        padding: 0;
        margin: 0;
        color: $lightgray-dark45;
      }
      small{
        padding-top: 5px;
        color: $lightgray-dark20;
        text-transform: uppercase;
      }
      ul{
        padding: 0;
        margin: 0;
        li{
          list-style: none;
          display: inline;
          &:not(:last-child):after{
            content: ", "
          }
        }
      }
    }
  }
}
