@import "common/variables";
@import "common/responsive";
@import "common/animation";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../../bower_components/slick-carousel/slick/slick-theme.scss";
@import "../../bower_components/select2/src/scss/core.scss";
// endbower

@import "../../bower_components/dist/jquery.fancybox.css";

// Film and Media Studies Styles
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/tables";
@import "components/grid";
@import "components/wp-classes";

@import "common/header";
@import "common/footer";
@import "common/sidebar";
@import "common/slick";

// Events and Tribe Overrides
@import "events/common";
@import "events/list";
@import "events/month";
@import "events/day";
@import "events/single";
@import "events/upcoming";

// Entities
@import "entity/entity";
// Course
@import "entity/course/archive";
@import "entity/course/single";
// Creative
@import "entity/creative/archive";
@import "entity/creative/single";
// Journal
@import "entity/journal/archive";
@import "entity/journal/single";
// People
@import "entity/people/archive";
@import "entity/people/grid";
@import "entity/people/single";
// Publication
@import "entity/publication/archive";
@import "entity/publication/single";



// Pages
@import "pages/pages";
@import "pages/posts";
@import "pages/tinymce";
// Home
@import "pages/home/research";
@import "pages/home/hero";
@import "pages/home/search";
@import "pages/home/links";
@import "pages/home/latest";
// Resources
@import "pages/resources/list";
@import "pages/resources/subnav";
@import "pages/gallery";
@import "pages/research";
@import "pages/interests";
@import "pages/about";







@import "common/search";
@import "elementor/elementor";
