
.fms-viz{
  background: $lightgray-dark60;
  .viz-actions{
    position: absolute;
    top: 15px;
    right: 15px;
    li{
      display: inline-block;
      margin-left: 10px;
      a{
        border-radius: 3px;
        font-size: 16px;
        padding: 5px 10px;
        background: $white;
        color: $lightgray-dark60;
        &:hover{
          text-decoration: none;
          background: $lightgray;
        }
      }
    }
  }
  .fms-viz__header{
    padding: 30px 0 10px;
    h2{
      font-size: 24px;
      color: $white;
    }
    p{
      color: #ccc;
      font-size: 16px;
      margin-bottom: 5px;
    }

    ul{
      padding: 0;
      margin: 0;
      font-size: 16px;
      li{
        list-style: none;
        display: inline-block;
        margin-right: 10px;
        &.viz-legend__person{
          color: #6aa0bd;
        }
        &.viz-legend__publication{
          color: #c77ed1;
        }
        &.viz-legend__creative{
          color: #936abc;
        }
        &.viz-legend__course{
          color: #73e5ca;
        }
      }
    }
  }
  .fms-viz__viz{
    background: darken($lightgray-dark60, 2%);
    min-height: 600px;
    position: relative;
    .node {
      cursor: pointer;

      &.top-term{
        circle{
          // display: none;
        }
        text{
          display: none;
          fill: $primary;
          font-size: 32px;
          font-weight: 300;
          // transform: translateX(20%);
        }
      }
    }
    .node.leaf {
      cursor: pointer;
    }
    .node circle {
      fill: transparent;
      stroke: #0591aa;
      stroke-width: 1.5px;
    }
    .node.leaf{
      text{
        font-size: 12px;
      }
      circle{
        stroke: transparent;
      }
      &.person text,
      &.person circle{
        fill: #6aa0bd;
      }
      &.publication text,
      &.publication circle{
        fill: #c77ed1;
      }
      &.course text,
      &.course circle{
        fill: #73e5ca;
      }
      &.creative text,
      &.creative circle{
        fill: #936abc;
      }
    }
    .node text {
      font-size: 16px;
      fill: #fff;
      opacity: 0.75;
      &:hover{
        opacity: 1;
      }
    }
    .link {
      fill: none;
      stroke: #ccc;
      stroke-width: 1.5px;
      opacity: 0.5;
    }
  }
}


.fms-viz__drawer{
  position: absolute;
  right: -750px;
  width: 350px;
  margin-right: 45px;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(darken($lightgray-dark60, 5%), .85);
  transition: all 0.25s ease;
  z-index:1001;
  &.active{
    right: 0;
  }
  .viz-drawer{
    padding: 15px;
    position: relative;
    .close-drawer{
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
      width: 16px;
      height: 16px;
      transition: transform .25s ease-in-out;
      &:hover{
        &:before,
        &:after{
          border-top: 1px solid rgba(255,255,255,1);
        }
      }
      &:before {
          content: "";
          position: absolute;
          display: block;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 16px;
          height: 0;
          border-top: 1px solid rgba(255,255,255,0.5);
          transform: rotate(45deg);
          transform-origin: center;
      }
      &:after {
          content: "";
          position: absolute;
          display: block;
          margin: auto;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 16px;
          height: 0;
          border-top: 1px solid rgba(255,255,255,0.5);
          transform: rotate(-45deg);
          transform-origin: center;
      }
    }
    p{
      margin-bottom: 5px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding-bottom: 5px;
      color: $white;
    }
    .viz-drawer__thumb{
      padding-bottom: 15px;
      min-height: 200px;
      img{
        width: 100%;
      }
    }
    .other-interests{
      color: $white;
      padding: 0;
      li{
        list-style: none;
      }
    }
    h2{
      color: $white;
      font-size: 18px;
    }
  }
}
