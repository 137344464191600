.fms-body--course-list{
  // background: $lightgray;
  padding: 50px 0;
  h4{
    color: $lightgray-dark60;
  }

}
.course-list{
  padding: 0;
  margin: 15px 0 0;
  li{
    list-style: none;
    padding: 10px 0 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $lightgray;
    h5{
      margin: 0;
    }
    h6{
      margin: 0;
    }

  }
}

.entity--course{
  .entity__meta{
    h3{
      a{
        font-size: 18px;
        padding-top: 5px;
        color: $primary;
      }
    }
    p.label.label--interests{
      padding-top: 0;
      border-top: 0;
    }
    p{
      padding-bottom: 0;
      font-size: 16px;
      strong{
        font-weight: 500;
      }
    }
    small{
      color: $black;
      font-weight: normal;
    }
  }
}
