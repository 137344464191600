// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    0;
$sans: Avenir, "Century Gothic", sans-serif;
$slab: Produkt, serif;
$header-font: $slab;

$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Colors
$black: #000000;
$white: #ffffff;
//
// UCSB Palette Guidelines
$navy: #003660;
$navy-dark4: #07528c;
$navy-light70: #c6e7ff;

$gold: #FEBC11;

$lightgray-dark80: #111517;
$lightgray-dark60: #3D4952;
$lightgray-dark45: #5E707E;
$lightgray-dark20: #A1AFBA;
$lightgray: #dce1e5;

$seagreen: #09847A;
$seagreen-dark12: #076f65;
$seagreen-light32: #83D3CC;

$aqua: #047C91;
$aqua-dark6: #036b7D;
$aqua-light36: #7acedd;

$mist: #9CBEBE;
$mist-dark10: #7CA9A9;

$primary: lighten($aqua, 5);
